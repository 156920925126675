import React, { useState } from "react";
import "./Carousel.css";

// Lista de fotos románticas
const photos = [
  {
    id: 1,
    src: "/images/1.png",
    alt: "Foto romántica 1",
    caption: "Siempre juntos 💖",
  },
  {
    id: 2,
    src: "/images/2.png",
    alt: "Foto romántica 2",
    caption: "Un amor eterno 💘",
  },
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextPhoto = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
  };

  const prevPhoto = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? photos.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="carousel">
      <div className="carousel-container">
        <button className="prev" onClick={prevPhoto}>
          ❮
        </button>
        <img src={photos[currentIndex].src} alt={photos[currentIndex].alt} />
        <div className="caption">
          <p>{photos[currentIndex].caption}</p>
        </div>
        <button className="next" onClick={nextPhoto}>
          ❯
        </button>
      </div>
    </div>
  );
};

export default Carousel;
