import React from "react";
import "./App.css";
import Carousel from "./components/Carousel";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Álbum de Amor 💖</h1>
      </header>
      <Carousel />
    </div>
  );
}

export default App;
